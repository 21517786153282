import React from 'react';
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  AlertIcon,
  Alert,
  Flex,
  Tfoot
} from "@chakra-ui/react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TabelaFinanceira = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Box mt={4} p={4}>
        <Alert status="warning">
          <AlertIcon />
          Intervalo de busca sem registros.
        </Alert>
      </Box>
    );
  }

  // Dados de vendas
  const dadosCompetencia = data[0].DataCompetencia;
  let ant = data[0].Antecipacao;
  let comp = data[0].DataPagamento;
  let DataPagamento = [];
  if (Array.isArray(ant) && ant.length > 0) {
    DataPagamento = [...DataPagamento, ...ant];
  }
  if (Array.isArray(comp) && comp.length > 0) {
    DataPagamento = [...DataPagamento, ...comp];
  }
  if (!Array.isArray(ant) && ant !== undefined) {
    DataPagamento.push(ant);
  }
  if (!Array.isArray(comp) && comp !== undefined) {
    DataPagamento.push(comp);
  }

  // Informações dos filtros
  const empresas = data[2];
  const bancos = data[3];
  const empresasList = empresas && empresas.length > 0
    ? empresas.map(emp => emp.label).join(", ")
    : "Nenhuma empresa selecionada";
  const bancoList = bancos && bancos.length > 0
    ? bancos.map(bac => bac.label).join(", ")
    : "Nenhum Banco selecionado";
  const inicio = data[1].inicio;
  const fim = data[1].fim;

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);
  };

  // Funções de agrupamento (para vendas e recebimentos)
  const groupByFormaPagamentoPai = (dataArray) => {
    return dataArray.reduce((acc, current) => {
      const { FormaPagamentoPai, DetalhamentoCreditoLoja } = current;
      let existingGroup = acc.find(item => item.FormaPagamentoPai === FormaPagamentoPai);
      if (existingGroup) {
        existingGroup.DetalhamentoCreditoLoja = existingGroup.DetalhamentoCreditoLoja.concat(DetalhamentoCreditoLoja);
      } else {
        acc.push({
          FormaPagamentoPai,
          DetalhamentoCreditoLoja
        });
      }
      return acc;
    }, []);
  };

  const groupByFormaPagamentoFilho = (groupedData) => {
    return groupedData
      .map(group => {
        const filhoGroup = {};
        group.DetalhamentoCreditoLoja.forEach(detalhes => {
          const detalhesArray = Array.isArray(detalhes) ? detalhes : [detalhes];
          detalhesArray.forEach(detalhe => {
            const formaPagamento = Array.isArray(detalhe.FormaPagamentoFilho)
              ? detalhe.FormaPagamentoFilho[0]
              : detalhe.FormaPagamentoFilho;
            const valor = detalhe.ValorTotalFilho;
            if (formaPagamento && valor > 0) {
              if (filhoGroup[formaPagamento]) {
                filhoGroup[formaPagamento] += valor;
              } else {
                filhoGroup[formaPagamento] = valor;
              }
            }
          });
        });
        group.DetalhamentoCreditoLoja = Object.keys(filhoGroup).map(forma => ({
          FormaPagamentoFilho: forma,
          ValorTotalFilho: filhoGroup[forma]
        }));
        return group;
      })
      .filter(group => group.DetalhamentoCreditoLoja.length > 0);
  };

  // Processamento dos dados de vendas (agrupamento para a tabela de Vendas)
  const agrupamentoRecebimentos = groupByFormaPagamentoPai(DataPagamento);
  const finalResultVendas = groupByFormaPagamentoFilho(agrupamentoRecebimentos);

  // Processamento dos dados de recebimentos vindos da API getCaixaVendas
  const recebimentosData = data[0].Recebimentos || [];
  const processRecebimentosData = (dataArray) => {
    const grouped = groupByFormaPagamentoPai(dataArray);
    return groupByFormaPagamentoFilho(grouped);
  };
  const agrupamentoRecebimentos2 = processRecebimentosData(recebimentosData);

  // NOVO TOTALIZADOR COMBINADO: Agrupa vendas e recebimentos por forma de pagamento
  const totalizadorCombined = {};

  // Soma dos totais das vendas, utilizando o agrupamento exibido (finalResultVendas)
  finalResultVendas.forEach(group => {
    if (group.FormaPagamentoPai) {
      // Soma os valores dos detalhes para cada forma de pagamento da venda
      const totalSales = group.DetalhamentoCreditoLoja.reduce(
        (sum, filho) => sum + filho.ValorTotalFilho,
        0
      );
      totalizadorCombined[group.FormaPagamentoPai] = (totalizadorCombined[group.FormaPagamentoPai] || 0) + totalSales;
    }
  });

  // Soma dos totais dos recebimentos (novo objeto)
  agrupamentoRecebimentos2.forEach(item => {
    item.DetalhamentoCreditoLoja.forEach(filho => {
      if (filho.FormaPagamentoFilho) {
        totalizadorCombined[filho.FormaPagamentoFilho] = (totalizadorCombined[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
      }
    });
  });

  const totalizadorArray = Object.entries(totalizadorCombined).map(([forma, valor]) => ({
    FormaPagamento: forma,
    ValorTotal: valor,
  }));
  const totalGeral = totalizadorArray.reduce((acc, item) => acc + item.ValorTotal, 0);

  const gerarPDF = () => {
    const doc = new jsPDF();
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(
      now.getMonth() + 1
    ).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(
      2,
      '0'
    )}:${String(now.getMinutes()).padStart(2, '0')}`;
  
    // Cabeçalho
    doc.text("Fechamento de Caixa Sintético", 10, 10);
    doc.setFontSize(10);
    doc.text(formattedDate, 10, 15);
  
    // Tabela de Vendas (Utilizando finalResultVendas – apenas os filhos)
    const vendasTableData = [['Forma de Recebimento', 'Valor Total']];
    let totalVendas = 0;
    finalResultVendas.forEach((group) => {
      group.DetalhamentoCreditoLoja.forEach((filho) => {
        vendasTableData.push([
          filho.FormaPagamentoFilho,
          formatarValor(filho.ValorTotalFilho),
        ]);
        totalVendas += filho.ValorTotalFilho;
      });
    });
    vendasTableData.push(['Total:', formatarValor(totalVendas)]);
    const vendasTitleY = 25;
    doc.text(`Vendas Realizadas - Período: ${inicio} até ${fim}`, 10, vendasTitleY);
    doc.autoTable({
      head: [vendasTableData[0]],
      body: vendasTableData.slice(1),
      startY: vendasTitleY + 5,
      styles: { fontSize: 10 },
    });
  
    // Tabela de Recebimentos (Utilizando agrupamentoRecebimentos2)
    // const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
    // agrupamentoRecebimentos2.forEach((group) => {
    //   // Linha com a forma de pagamento (pai)
    //   recebimentosTableData.push([group.FormaPagamentoPai, '', '']);
    //   // Linhas com os detalhes (filhos)
    //   group.DetalhamentoCreditoLoja.forEach((filho) => {
    //     recebimentosTableData.push([
    //       '',
    //       filho.FormaPagamentoFilho,
    //       formatarValor(filho.ValorTotalFilho),
    //     ]);
    //   });
    // });
    // const totalRecebimentos = agrupamentoRecebimentos2.reduce((total, group) => {
    //   return total + group.DetalhamentoCreditoLoja.reduce((sum, filho) => sum + filho.ValorTotalFilho, 0);
    // }, 0);
    // recebimentosTableData.push(['Total:', '', formatarValor(totalRecebimentos)]);
    // const recebimentosTitleY = doc.lastAutoTable.finalY + 10;
    // doc.text(`Recebimentos - Período: ${inicio} até ${fim}`, 10, recebimentosTitleY);
    // doc.autoTable({
    //   head: [recebimentosTableData[0]],
    //   body: recebimentosTableData.slice(1),
    //   startY: recebimentosTitleY + 5,
    //   styles: { fontSize: 10 },
    // });
  
    // Salva o PDF
    doc.save("rel_caixa_vendas.pdf");
  };
  

  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold" mb={2}>Filtros Selecionados</Text>
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={6} bg="gray.50" shadow="sm">
        <Flex wrap="wrap" gap={6} alignItems="center">
          <Box>
            <Text fontWeight="bold" color="gray.600">Período:</Text>
            <Text color="gray.700">{inicio} até {fim}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" color="gray.600">Empresas:</Text>
            <Text color="gray.700">{empresasList}</Text>
          </Box>
          {/* <Box>
            <Text fontWeight="bold" color="gray.600">Bancos:</Text>
            <Text color="gray.700">{bancoList}</Text>
          </Box> */}
        </Flex>
      </Box>

      {/* Tabela de Vendas */}
      <Box>
        <TableContainer>
          <Text fontSize="lg" fontWeight="bold" mb={2}>Vendas Realizadas</Text>
          <Table variant="striped" width="100%" size="sm">
            <Thead>
              <Tr>
                {/* <Th textAlign="center" width="40%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Forma de Pagamento
                </Th> */}
                <Th textAlign="center" width="40%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Forma de Recebimento
                </Th>
                <Th isNumeric width="20%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Valor Total
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {finalResultVendas.map((dado, index) => (
                <React.Fragment key={index}>
                  {/* <Tr>
                    <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr> */}
                  {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
                    <Tr key={filhoIndex}>
                      {/* <Td></Td> */}
                      <Td>{filho.FormaPagamentoFilho}</Td>
                      <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th fontWeight="bold">Total:</Th>
                {/* <Td></Td> */}
                <Td isNumeric fontWeight="bold">
                  {formatarValor(
                    finalResultVendas.reduce((total, dado) => {
                      return total + dado.DetalhamentoCreditoLoja.reduce((subTotal, filho) => subTotal + filho.ValorTotalFilho, 0);
                    }, 0)
                  )}
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>

      {/* Tabela de Recebimentos */}
      {/* <Box mt={6}>
        <TableContainer>
          <Text fontSize="lg" fontWeight="bold" mb={2}>Recebimentos</Text>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center" width="40%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Forma de Pagamento
                </Th>
                <Th textAlign="center" width="40%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Forma de Recebimento
                </Th>
                <Th isNumeric width="20%" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                  Valor Total
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {agrupamentoRecebimentos2.map((dado, index) => (
                <React.Fragment key={index}>
                  <Tr>
                    <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                  {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
                    <Tr key={filhoIndex}>
                      <Td></Td>
                      <Td>{filho.FormaPagamentoFilho}</Td>
                      <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th fontWeight="bold">Total:</Th>
                <Td></Td>
                <Td isNumeric fontWeight="bold">
                  {formatarValor(
                    agrupamentoRecebimentos2.reduce((total, dado) => {
                      return total + dado.DetalhamentoCreditoLoja.reduce((subTotal, filho) => subTotal + filho.ValorTotalFilho, 0);
                    }, 0)
                  )}
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box> */}

      {/* Tabela Totalizador COMBINADO */}
      {/* <TableContainer mt={4}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>Total do Fechamento Sintético</Text>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="center" bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                Forma de Pagamento
              </Th>
              <Th isNumeric bg="#5e9ff2" textColor="#FFFFFF" borderWidth="1px" borderColor="black">
                Valor Total
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {totalizadorArray.map((item, index) => (
              <Tr key={index}>
                <Td>{item.FormaPagamento}</Td>
                <Td isNumeric>{formatarValor(item.ValorTotal)}</Td>
              </Tr>
            ))}
          </Tbody>
          <tfoot>
            <Tr>
              <Th fontWeight="bold">Total Geral:</Th>
              <Td isNumeric fontWeight="bold">{formatarValor(totalGeral)}</Td>
            </Tr>
          </tfoot>
        </Table>
      </TableContainer> */}

      <Button onClick={gerarPDF} colorScheme="blue" mt={4}>Gerar PDF</Button>
    </Box>
  );
};

export default TabelaFinanceira;
