import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MultiSelect } from "react-multi-select-component";



import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from './components/table';
import ModalComponent from './components/modal';


function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}


function generatePDF(excel, total, filtros) {

  let tableFiltro = transformaFiltros([filtros]);
  let result = [];

  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }


  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }


  doc.setFontSize(18);
  doc.text('Notas Fiscais Emitidas', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('Notas_fiscais.pdf');
}

function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Vendida') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Devolvida') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Porcentagem') {
        totalizadores[key] = '100%'
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Qtd. Vendida' && key !== 'Qtd. Devolvida') {
      value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}


function calcularPorcentagem(objetos) {
  // Calcula o valor total
  const valorTotal = objetos.reduce((total, objeto) => {
    const valor = parseFloat(objeto["Valor Total"].replace(/[^\d,-]+/g, '').replace(',', '.'));
    return total + valor;
  }, 0);

  // Adiciona a porcentagem a cada objeto
  objetos.forEach(objeto => {
    const valor = parseFloat(objeto["Valor Total"].replace(/[^\d,-]+/g, '').replace(',', '.'));
    const porcentagem = (valor / valorTotal) * 100;

    objeto.Porcentagem = parseFloat(porcentagem.toFixed(2));
  });

  // Ordena os objetos pelo maior valor de porcentagem
  objetos.sort((a, b) => b.Porcentagem - a.Porcentagem);

  if (objetos.length === 1) {
    objetos[0].Classe = (
      <span style={{ color: 'green' }}>A</span>
    );
    return objetos;
  }

  // Adiciona a classe a cada objeto
  // Define os limites das classes
  const limiteA = 80;
  const limiteB = 95;

  // Adiciona a classe a cada objeto
  let porcentagemAcumulada = 0;
  objetos.forEach(objeto => {
    if (porcentagemAcumulada + objeto.Porcentagem <= limiteA) {
      objeto.Classe = (
        <span style={{ color: 'green' }}>A</span>
      );
    } else if (porcentagemAcumulada < limiteB) {
      objeto.Classe = (
        <span style={{ color: 'blue' }}>B</span>
      );
    } else {
      objeto.Classe = (
        <span style={{ color: 'red' }}>C</span>
      );
    }

    porcentagemAcumulada += objeto.Porcentagem;
  });


  return objetos;
}


const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(valor);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function ImprimirValor( valor ) {
  console.log(valor)
  return (
    <Text>
      {valor ? 'NFC-e' : 'NFe'}
    </Text>
  );
}

function generateTableData(analitico, dataAtual) {

  if (analitico.length === 0) {
    const keys = [
      // "Código",
      // "Descrição",
      // "Saldo",
      // "P. Venda Atual",
      // "P. Médio Vendas",
      // "Qtd. Vendida",
      // "Qtd. Devolvida",
      // "Valor Devolução",
      // "Valor Total",
      // "Classe"
      "Numero NF-e,",
      "Tipo",
      "Serie",
      "Data Autorização",
      "Status",
      "Lote",
      "CNPJ Emissor",
      "Cliente",
      "Destinatario RazaoSocial",
      "Destinatario CNPJ",
      "Valor Nota",
      "ICMS",
      "IPI",
      "ST",
      "PIS",
      "Cofins",
      "CFOP",
      "Numero Protocolo",
      "Chave Acesso",
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);
  return analitico.map((item) => ({
    "Numero NF-e,": item.NumeroNFe,
    "Tipo": ImprimirValor(item.EhNFCe),
    "Serie": item.SerieNFe,
    "Data Autorização": formatarDataHora(item.DataNFe),
    "Status": item.Status,
    "Lote": item.LoteNumero,
    "CNPJ Emissor": item.CNPJEmissor,
    "Cliente": item.Cliente,
    "Destinatario RazaoSocial": item.DestinatarioRazaoSocial,
    "Destinatario CNPJ": item.DestinatarioCNPJ,
    "Valor Nota": formatarValor(item.ValorNota),
    "ICMS": formatarValor(item.ICMS),
    "IPI": formatarValor(item.IPI),
    "ST": formatarValor(item.ICMSST),
    "PIS": formatarValor(item.pis),
    "Cofins": formatarValor(item.cofins),
    "CFOP": formatarValor(item.CFOP),
    "Numero Protocolo": item.NumeroProtocolo,
    "Chave Acesso": item.NFeID,
    // "Código": item._id,
    // "Descrição": item.nomeProduto,
    // "Saldo": item.vEstoqueAtual,
    // "P. Venda Atual": formatarValor(item.vVenda),
    // "P. Médio Vendas": formatarValor(item.vTotalVendas / item.qtVendido),
    // "Qtd. Vendida": item.qtVendido,
    // "Qtd. Devolvida": item.qtDevolucao,
    // "Valor Devolução": formatarValor(item.valorDevolucao),
    // "Valor Total": formatarValor(
    //   item.vTotalVendas + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto - item.valorDevolucao
    // ),
    // "Classe" : ''
  }));
}


function gerarExcel(dados, objeto) {
  const formatarValorParaExcel = (valor) => {
    if (typeof valor === "string" && valor.includes("R$")) {
      const valorLimpo = valor
        .replace("R$", "") // Remove "R$"
        .replace(/\s/g, "") // Remove espaços
        .replace(/\./g, "") // Remove separadores de milhares
        .replace(",", "."); // Substitui vírgula decimal por ponto decimal
      return parseFloat(valorLimpo) || 0; // Converte para número e retorna 0 se NaN
    }
    return valor; // Retorna o valor original se não for string no formato "R$"
  };

  // Mapear e formatar os dados para Excel
  const dadosFormatados = dados.map((item) => {
    const itemFormatado = { ...item };
    for (const chave in itemFormatado) {
      if (typeof itemFormatado[chave] === "string" && itemFormatado[chave].includes("R$")) {
        itemFormatado[chave] = formatarValorParaExcel(itemFormatado[chave]);
      }
    }
    return itemFormatado;
  });

  // Criar workbook e worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);


  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'notas_analitico.xlsx');
}


// function transformaFiltros(data) {
//   return data.map((item) => {
//     const filtro = {};

//     if (item.status) {
//       filtro['Status: '] = (item.status);
//     }

//     if (item.empresa) {
//       filtro['Empresa: '] = item.empresa;
//     }

//     if (item.tabela) {
//       filtro['Tabela de Preço: '] = item.tabela;
//     }

//     if (item.categoriaP) {
//       filtro['Categoria Produto: '] = item.categoriaP;
//     }

//     if (item.genero) {
//       filtro['Genero Produto: '] = item.genero;
//     }

//     if (item.deposito) {
//       filtro['Deposito: '] = item.deposito;
//     }

//     if (item.prod) {
//       filtro['Produto: '] = item.prod;
//     }

//     if (item.origem) {
//       filtro['Origem da Venda: '] = item.origem;
//     }

//     if (item.vendedor) {
//       filtro['Vendedor: '] = item.vendedor;
//     }

//     if (item.plano) {
//       filtro['Plano de Contas: '] = item.plano;
//     }

//     if (item.inicio) {
//       filtro['Data Inicio: '] = formatarDataHora(item.inicio);
//     }

//     if (item.fim) {
//       filtro['Data Final: '] = formatarDataHora(item.fim);
//     }

//     return filtro;
//   });
// }

function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.cliente) {
      filtro['Cliente: '] = (item.cliente);
    }

    if (item.cfop) {
      filtro['CFOP: '] = item.cfop;
    }

    if (item.tabela) {
      filtro['Tabela de Preço: '] = item.tabela;
    }

    if (item.multi) {
      const statusValues = item.multi.map(obj => obj.value);
      filtro['Status: '] = statusValues;
    }

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}

function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}


export default function UserReports() {
  const [value, setValue] = useState([])
  const [value2, setValue2] = useState([])
  const [finalid, setFinalidade] = useState([])
  const [tipo, setTipo] = useState([])
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('Pedido Faturado');
  const [empresa, setEmpresa] = useState('');
  // const [origem, setOrigens] = useState('');
  // const [prod, setFiltroProd] = useState('');
  // const [categVenda, setCategVenda] = useState('');
  // const [deposito, setDeposito] = useState('');
  // const [vendedor, setVendedor] = useState('');
  // const [tabela, setTabela] = useState('');
  const [cfop, setCFOP] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [cliente, setCliente] = useState('');
  const [generoProd, setGeneroProd] = useState("00 – Mercadoria para Revenda");
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const options = Array.from(event.target.selectedOptions, (option) => option.value);
    setStatus(options);
  };

  const options = [
    { value: 'Autorizado o Uso', label: 'Autorizado o Uso' },
    { value: 'NFe Cancelada', label: 'NFe Cancelada' },
    { value: 'NFe com Uso Denegado', label: 'NFe com Uso Denegado' },
  ];


  const options2 = [
    { value: true, label: 'NFC-e' },
    { value: false, label: 'NFe' },
  ];

  const options3 = [
    { value: "0", label: 'Entrada' },
    { value: "1", label: 'Saida' },
  ];

  const finalidade = [
    { value: "1", label: 'Normal' },
    { value: "2", label: 'NF-e Complementar' },
    { value: "3", label: 'Ajuste' },
    { value: "4", label: 'Devolução' },
    { value: "5", label: 'Transferência' },
  ];

  // const options = [
  //   { label: "Grapes 🍇", value: "grapes" },
  //   { label: "Mango 🥭", value: "mango" },
  //   { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  // ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {
    console.log(data)

    const dados_formatados = await generateTableData(data, diaAtual)
    // const teste = calcularPorcentagem(dados_formatados);
    // console.log(teste)

    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)
    // console.log("dados formatados")
    // console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/analitico_filtros', { params: param, timeout: 500000 }),
            api.get('/fiscal-notas', { params: param, timeout: 5000000 }),
          ]);

          const filtros = filtrosData.data;
          const dados = dadosData.data[0];

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          const select_empresas = [...new Set(filtros[0].map(item => item.NomeFantasia))];

          const selectEmpresas = document.getElementById("select-empresas");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((value !== '') && value !== null) {
        data.multi = value
      }

      if ((finalid !== '') && finalid !== null) {
        data.finalidade = finalid
      }

      if ((value2 !== '') && value2 !== null) {
        data.operacao = value2
      }

      if ((empresa !== '') && empresa !== null) {
        data.empresa = empresa
      }

      if ((tipo !== '') && tipo !== null) {
        data.tipo = tipo
      }

      if ((cfop !== '') && cfop !== null) {
        data.cfop = cfop
      }

      if ((cliente !== '') && cliente !== null) {
        data.cliente = cliente
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/fiscal-notas', { params: data, timeout: 1000000 })
          .then(response => {
            // console.log(response.data)

            if (response.data[0].length !== 0) {
              // console.log(response.data[0])
              setDados(response.data[0]);
              setTotalItems(response.data[0].length);
            } else {
              setDados(response.data[0]);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data[0]);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">CFOP:</FormLabel>
            <Input type="input" id="cfop" onChange={(e) => setCFOP(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Cliente:</FormLabel>
            <Input type="input" id="cli" onChange={(e) => setCliente(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Operação:</FormLabel>
            <MultiSelect
              options={options3}
              value={value2}
              onChange={setValue2}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Search",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Create",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Finalidade da Operação:</FormLabel>
            <MultiSelect
              options={finalidade}
              value={finalid}
              onChange={setFinalidade}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Search",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Create",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status:</FormLabel>
            <MultiSelect
              options={options}
              value={value}
              onChange={setValue}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Search",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Create",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Documento:</FormLabel>
            <MultiSelect
              options={options2}
              value={tipo}
              onChange={setTipo}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Search",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Create",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt='1%'>
        <Button size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='2%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}
