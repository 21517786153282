import React from 'react';
import { Box, Button, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, AlertIcon, Alert, Flex, Divider, Tfoot } from "@chakra-ui/react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TabelaFinanceira = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Box mt={4} p={4}>
        <Alert status="warning">
          <AlertIcon />
          Intervalo de busca sem registros.
        </Alert>
      </Box>
    );
  }


  const dadosCompetencia = data[0].DataCompetencia;

  // console.log(dadosCompetencia, "competencia")
  // const DataPagamento = data[0].DataPagamento;
  // let DataPagamento = [];
  
  let ant = data[0].Antecipacao;
  // let ant = data[0].Antecipacao;
  
  console.log(data[0], "antecipacao")
  let comp = data[0].DataPagamento;

  let DataPagamento = [];

  // Verifique se `ant` e `comp` são arrays não vazios ou valores definidos
  if (Array.isArray(ant) && ant.length > 0) {
    DataPagamento = [...DataPagamento, ...ant];
  }
  if (Array.isArray(comp) && comp.length > 0) {
    DataPagamento = [...DataPagamento, ...comp];
  }

  // Caso `ant` e `comp` sejam strings ou valores únicos, adicione-os ao array
  if (!Array.isArray(ant) && ant !== undefined) {
    DataPagamento.push(ant);
  }
  if (!Array.isArray(comp) && comp !== undefined) {
    DataPagamento.push(comp);
  }

  // console.log("DATA PAGAMENTO", DataPagamento)
  const empresas = data[2];
  const bancos = data[3];
  // console.log("empres", bancos)
  const empresasList = empresas && empresas.length > 0
    ? empresas.map(emp => emp.label).join(", ")
    : "Nenhuma empresa selecionada";

  const bancoList = bancos && bancos.length > 0
    ? bancos.map(bac => bac.label).join(", ")
    : "Nenhum Banco selecionado";

  // Obtendo as variáveis de início e fim
  const inicio = data[1].inicio; // Supondo que "Inicio" e "Fim" estão disponíveis em data[0]
  const fim = data[1].fim;

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);
  };


  const agrupamentoRecebimentosf = DataPagamento

  const groupByFormaPagamentoPai = (data) => {
    return data.reduce((acc, current) => {
      const { FormaPagamentoPai, DetalhamentoCreditoLoja } = current;

      let existingGroup = acc.find(item => item.FormaPagamentoPai === FormaPagamentoPai);

      if (existingGroup) {
        existingGroup.DetalhamentoCreditoLoja = existingGroup.DetalhamentoCreditoLoja.concat(DetalhamentoCreditoLoja);
      } else {
        acc.push({
          FormaPagamentoPai,
          DetalhamentoCreditoLoja: DetalhamentoCreditoLoja
        });
      }

      return acc;
    }, []);
  };

  // Função para agrupar por FormaPagamentoFilho dentro de cada grupo
  const groupByFormaPagamentoFilho = (groupedData) => {
    return groupedData
      .map(group => {
        const filhoGroup = {};
  
        // Garante que cada item em `DetalhamentoCreditoLoja` seja um array
        group.DetalhamentoCreditoLoja.forEach(detalhes => {
          const detalhesArray = Array.isArray(detalhes) ? detalhes : [detalhes];
  
          detalhesArray.forEach(detalhe => {
            const formaPagamento = Array.isArray(detalhe.FormaPagamentoFilho) ? detalhe.FormaPagamentoFilho[0] : detalhe.FormaPagamentoFilho;
            const valor = detalhe.ValorTotalFilho;
  
            if (formaPagamento && valor > 0) {
              if (filhoGroup[formaPagamento]) {
                filhoGroup[formaPagamento] += valor;
              } else {
                filhoGroup[formaPagamento] = valor;
              }
            }
          });
        });
  
        // Converte `filhoGroup` em um array de objetos
        group.DetalhamentoCreditoLoja = Object.keys(filhoGroup).map(forma => ({
          FormaPagamentoFilho: forma,
          ValorTotalFilho: filhoGroup[forma]
        }));
  
        return group;
      })
      // Filtra grupos com `DetalhamentoCreditoLoja` vazio ou com itens sem `FormaPagamentoFilho` e `ValorTotalFilho` igual a 0
      .filter(group => group.DetalhamentoCreditoLoja.length > 0);
  };

  // Primeira etapa: agrupar por FormaPagamentoPai
  const groupedByPai = groupByFormaPagamentoPai(agrupamentoRecebimentosf);

  // Segunda etapa: agrupar e somar por FormaPagamentoFilho dentro de cada pai
  const finalResult = groupByFormaPagamentoFilho(groupedByPai);

  const agrupamentoRecebimentos = finalResult || [];

  console.log("aqui", dadosCompetencia)
  // Total das Vendas
  // const totalVendas = dadosCompetencia.reduce((acc, dado) => acc + dado.ValorTotalPai, 0);

  const totalVendas = dadosCompetencia.reduce((acc, dado) => {
    return dado.FormaPagamentoPai ? acc + dado.ValorTotalPai : acc;
  }, 0);
  

  // Totalizador para somar as formas de pagamento iguais
  const totalizador = {};

  // Adicionando as vendas ao totalizador
  dadosCompetencia.forEach(dado => {
    if (dado.FormaPagamentoPai) {
      totalizador[dado.FormaPagamentoPai] = (totalizador[dado.FormaPagamentoPai] || 0) + dado.ValorTotalPai;
    }
  });

  // Adicionando os recebimentos ao totalizador
  agrupamentoRecebimentos.forEach(dado => {
    dado.DetalhamentoCreditoLoja.forEach(filho => {
      totalizador[filho.FormaPagamentoFilho] = (totalizador[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
    });
  });

  // Criando um array para o totalizador que será usado na tabela
  const totalizadorArray = Object.entries(totalizador).map(([formaPagamento, valorTotal]) => ({
    FormaPagamento: formaPagamento,
    ValorTotal: valorTotal,
  }));

  const totalTotalizador = totalizadorArray.reduce((acc, item) => acc + item.ValorTotal, 0);

  const totalRecebimentos = agrupamentoRecebimentos.reduce((total, dado) => {
    const totalDetalhamento = dado.DetalhamentoCreditoLoja.reduce((subTotal, filho) => {
      return subTotal + filho.ValorTotalFilho;
    }, 0);
    return total + totalDetalhamento;
  }, 0);

  const gerarPDF = () => {
    const doc = new jsPDF();
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

    // Título inicial sem a data ao lado
    doc.text("Fechamento de Caixa Sintético", 10, 10);
    // Data embaixo do título com fonte menor
    doc.setFontSize(10);
    doc.text(formattedDate, 10, 15);

    // Bloco Vendas
    const vendasTableData = [['Forma de Pagamento Vendas', 'Valor Total']];
    let totalVendas = 0;
    dadosCompetencia.forEach((dado) => {
      vendasTableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
      totalVendas += dado.ValorTotalPai;
    });

    // Adiciona linha de total na tabela Vendas
    vendasTableData.push(['Total', formatarValor(totalVendas)]);

    const vendasTitleY = 25;
    doc.text(`Lançamentos Realizados em: ${inicio} até ${fim}`, 10, vendasTitleY);
    doc.autoTable({
      head: [vendasTableData[0]],
      body: vendasTableData.slice(1),
      startY: vendasTitleY + 5,
      styles: { fontSize: 10 },
    });

    // Bloco Recebimentos das Vendas
    const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
    let totalRecebimentos = 0;
    agrupamentoRecebimentos.forEach((dado) => {
      dado.DetalhamentoCreditoLoja.forEach((filho) => {
        recebimentosTableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
        totalRecebimentos += filho.ValorTotalFilho;
      });
    });

    // Adiciona linha de total na tabela Recebimentos
    recebimentosTableData.push(['Total', '', formatarValor(totalRecebimentos)]);

    const recebimentosTitleY = doc.lastAutoTable.finalY + 10;
    doc.text(`Valores Recebidos a Prazo em: ${inicio} até ${fim}`, 10, recebimentosTitleY);
    doc.autoTable({
      head: [recebimentosTableData[0]],
      body: recebimentosTableData.slice(1),
      startY: recebimentosTitleY + 5,
      styles: { fontSize: 10 },
    });

    // Bloco Totalizador
    const totalizadorTableData = [['Forma de Pagamento', 'Valor Total']];
    let totalGeral = 0;
    totalizadorArray.forEach(item => {
      totalizadorTableData.push([item.FormaPagamento, formatarValor(item.ValorTotal)]);
      totalGeral += item.ValorTotal;
    });

    // Adiciona linha de total na tabela Totalizador
    totalizadorTableData.push(['Total', formatarValor(totalGeral)]);

    const totalizadorTitleY = doc.lastAutoTable.finalY + 10;
    doc.text(`Total do Fechamento: ${inicio} até ${fim}`, 10, totalizadorTitleY);
    doc.autoTable({
      head: [totalizadorTableData[0]],
      body: totalizadorTableData.slice(1),
      startY: totalizadorTitleY + 5,
      styles: { fontSize: 10 },
    });

    // Adicionando a linha de total geral no PDF
    // const totalizadorPDFY = doc.lastAutoTable.finalY + 5;
    // doc.setFontSize(12);
    // doc.text('Total Geral:', 10, totalizadorPDFY);
    // doc.text(formatarValor(totalTotalizador), 100, totalizadorPDFY);

    doc.save("relatorio_caixa_sintetico.pdf");
  };


  // const gerarPDF = () => {
  //   const doc = new jsPDF();
  //   const now = new Date();
  //   const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  //   doc.text("Fechamento de Caixa - " + formattedDate, 10, 10);

  //   // Bloco Vendas
  //   const vendasTableData = [['Forma de Pagamento Vendas', 'Valor Total']];
  //   dadosCompetencia.forEach((dado) => {
  //     vendasTableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
  //   });

  //   const vendasTitleY = 20;
  //   doc.text(`Lançamentos Realizados em: ${inicio} até ${fim}`, 10, vendasTitleY);
  //   doc.autoTable({
  //     head: [vendasTableData[0]],
  //     body: vendasTableData.slice(1),
  //     startY: vendasTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   // Bloco Recebimentos das Vendas
  //   const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
  //   agrupamentoRecebimentos.forEach((dado) => {
  //     dado.DetalhamentoCreditoLoja.forEach((filho) => {
  //       recebimentosTableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
  //     });
  //   });

  //   const recebimentosTitleY = doc.lastAutoTable.finalY + 10;
  //   doc.text(`Valores Recebidos a Prazo em: ${inicio} até ${fim}`, 10, recebimentosTitleY);
  //   doc.autoTable({
  //     head: [recebimentosTableData[0]],
  //     body: recebimentosTableData.slice(1),
  //     startY: recebimentosTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   // Bloco Totalizador
  //   const totalizadorTableData = [['Forma de Pagamento', 'Valor Total']];
  //   totalizadorArray.forEach(item => {
  //     totalizadorTableData.push([item.FormaPagamento, formatarValor(item.ValorTotal)]);
  //   });

  //   const totalizadorTitleY = doc.lastAutoTable.finalY + 10;
  //   doc.text(`Total do Período: ${inicio} até ${fim}`, 10, totalizadorTitleY);
  //   doc.autoTable({
  //     head: [totalizadorTableData[0]],
  //     body: totalizadorTableData.slice(1),
  //     startY: totalizadorTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   // Adicionando a linha total no PDF
  //   const totalizadorPDFY = doc.lastAutoTable.finalY + 5;
  //   doc.text('Total Geral:', 10, totalizadorPDFY);
  //   doc.text(formatarValor(totalTotalizador), 100, totalizadorPDFY);

  //   doc.save("relatorio_caixa_sintetico.pdf");
  // };

  // const gerarPDF = () => {
  //   const doc = new jsPDF();
  //   const now = new Date();
  //   const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  //   doc.text("Fechamento de Caixa - " + formattedDate, 10, 10);

  //   // Bloco Vendas
  //   const vendasTableData = [['Forma de Pagamento Vendas', 'Valor Total']];
  //   dadosCompetencia.forEach((dado) => {
  //     vendasTableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
  //   });

  //   const vendasTitleY = 20;
  //   doc.text(`Lançamentos Realizados em: ${inicio} até ${fim}`, 10, vendasTitleY);
  //   doc.autoTable({
  //     head: [vendasTableData[0]],
  //     body: vendasTableData.slice(1),
  //     startY: vendasTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   // Bloco Recebimentos das Vendas
  //   const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
  //   agrupamentoRecebimentos.forEach((dado) => {
  //     dado.DetalhamentoCreditoLoja.forEach((filho) => {
  //       recebimentosTableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
  //     });
  //   });

  //   const recebimentosTitleY = doc.lastAutoTable.finalY + 10;
  //   doc.text(`Valores Recebidos a Prazo em: ${inicio} até ${fim}`, 10, recebimentosTitleY);
  //   doc.autoTable({
  //     head: [recebimentosTableData[0]],
  //     body: recebimentosTableData.slice(1),
  //     startY: recebimentosTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   // Bloco Totalizador
  //   const totalizadorTableData = [['Forma de Pagamento', 'Valor Total']];
  //   totalizadorArray.forEach(item => {
  //     totalizadorTableData.push([item.FormaPagamento, formatarValor(item.ValorTotal)]);
  //   });

  //   const totalizadorTitleY = doc.lastAutoTable.finalY + 10;
  //   doc.text(`Total do Período: ${inicio} até ${fim}`, 10, totalizadorTitleY);
  //   doc.autoTable({
  //     head: [totalizadorTableData[0]],
  //     body: totalizadorTableData.slice(1),
  //     startY: totalizadorTitleY + 5,
  //     styles: { fontSize: 10 },
  //   });

  //   doc.save("relatorio_caixa_sintetico.pdf");
  // };

  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold" mb={2}>Filtros Selecionados</Text>
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={6} bg="gray.50" shadow="sm">
        <Flex wrap="wrap" gap={6} alignItems="center">
          <Box>
            <Text fontWeight="bold" color="gray.600">Período:</Text>
            <Text color="gray.700">{inicio} até {fim}</Text>
          </Box>

          <Box>
            <Text fontWeight="bold" color="gray.600">Empresas:</Text>
            <Text color="gray.700">{empresasList}</Text>
          </Box>

          <Box>
            <Text fontWeight="bold" color="gray.600">Bancos:</Text>
            <Text color="gray.700">{bancoList}</Text>
          </Box>
        </Flex>
      </Box>


      {/* <Box display="flex" gap={4} mb={8}>
        
        <TableContainer flex="1">
          <Text fontSize="lg" fontWeight="bold" mb={2}>Vendas Realizados:</Text>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Vendas</Th>
                <Th isNumeric width="30%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dadosCompetencia.map((dado, index) => (
                <Tr key={index}>
                  <Td>{dado.FormaPagamentoPai}</Td>
                  <Td isNumeric>{formatarValor(dado.ValorTotalPai)}</Td>
                </Tr>
              ))}
            </Tbody>
            <tfoot>
              <Tr>
                <Th fontWeight="bold">Total</Th>
                <Td isNumeric fontWeight="bold">{formatarValor(totalVendas)}</Td>
              </Tr>
            </tfoot>
          </Table>
        </TableContainer>

        
        <TableContainer flex="1">
          <Text fontSize="lg" fontWeight="bold" mb={2}>Valores Recebidos a Prazo</Text>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Recebimento</Th>
                <Th isNumeric width="20%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agrupamentoRecebimentos.map((dado, index) => (
                <React.Fragment key={index}>
                  <Tr>
                    <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                  {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
                    <Tr key={filhoIndex}>
                      <Td></Td>
                      <Td>{filho.FormaPagamentoFilho}</Td>
                      <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
            <tfoot>
              <Tr>
                <Th fontWeight="bold">Total:</Th>
                <Td></Td>
                <Td isNumeric fontWeight="bold">{formatarValor(totalRecebimentos)}</Td>
              </Tr>
            </tfoot>
          </Table>
        </TableContainer>
      </Box> */}

      <Box mb={6}>
        <TableContainer>
          <Text fontSize="lg" fontWeight="bold" mb={2}>Vendas Realizadas</Text>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Vendas</Th>
                <Th isNumeric width="30%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dadosCompetencia
                .filter(dado => dado.FormaPagamentoPai !== null && dado.FormaPagamentoPai !== undefined)
                .map((dado, index) => (
                  <Tr key={index}>
                    <Td>{dado.FormaPagamentoPai}</Td>
                    <Td isNumeric>{formatarValor(dado.ValorTotalPai)}</Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th fontWeight="bold">Total</Th>
                <Td isNumeric fontWeight="bold">{formatarValor(totalVendas)}</Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <TableContainer>
          <Text fontSize="lg" fontWeight="bold" mb={2}>Valores Recebidos a Prazo</Text>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
                <Th textAlign="center" width="40%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Recebimento</Th>
                <Th isNumeric width="20%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agrupamentoRecebimentos.map((dado, index) => (
                <React.Fragment key={index}>
                  <Tr>
                    <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                  {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
                    <Tr key={filhoIndex}>
                      <Td></Td>
                      <Td>{filho.FormaPagamentoFilho}</Td>
                      <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th fontWeight="bold">Total:</Th>
                <Td></Td>
                <Td isNumeric fontWeight="bold">{formatarValor(totalRecebimentos)}</Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>


      {/* Tabela Totalizador */}
      <TableContainer mt={4}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>Total do Fechamento Sintérico</Text>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
              <Th isNumeric bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {totalizadorArray.map((item, index) => (
              <Tr key={index}>
                <Td>{item.FormaPagamento}</Td>
                <Td isNumeric>{formatarValor(item.ValorTotal)}</Td>
              </Tr>
            ))}
          </Tbody>
          <tfoot>
            <Tr>
              <Th fontWeight="bold">Total Geral:</Th>
              <Td isNumeric fontWeight="bold">{formatarValor(totalTotalizador)}</Td>
            </Tr>
          </tfoot>
        </Table>
      </TableContainer>

      <Button onClick={gerarPDF} colorScheme="blue" mt={4}>Gerar PDF</Button>
    </Box>
  );
};

export default TabelaFinanceira;

