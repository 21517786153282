

// const TabelaFinanceira = ({ data }) => {
//   const formatarValor = (valor) => {
//     const formatter = new Intl.NumberFormat('pt-BR', {
//       style: 'currency',
//       currency: 'BRL',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//     return formatter.format(valor);
//   };

//   const gerarPDF = () => {
//     const doc = new jsPDF();

//     // Adiciona o título do relatório no início
//     const now = new Date();
//     const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
//     doc.text("Fechamento de Caixa - " + formattedDate, 10, 10);

//     // Espaço após o título do relatório
//     doc.text("", 10, 15); // Linha em branco para espaçamento

//     // Bloco Vendas
//     const vendasTableData = [['Forma de Pagamento Vendas', 'Valor Total']];
//     data.forEach((dado) => {
//       vendasTableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
//     });

//     // Adiciona o título da tabela Vendas
//     const vendasTitleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20; // Ajuste para o caso de não haver tabelas anteriores
//     doc.text("Vendas", 10, vendasTitleY);

//     doc.autoTable({
//       head: [vendasTableData[0]],
//       body: vendasTableData.slice(1),
//       startY: vendasTitleY + 5, // Início da tabela 5 unidades abaixo do título
//       styles: { fontSize: 10 },
//     });

//     // Bloco Recebimentos
//     const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
//     data
//       .filter((dado) => dado.DetalhamentoCreditoLoja.length > 0)
//       .forEach((dado) => {
//         dado.DetalhamentoCreditoLoja.forEach((filho) => {
//           recebimentosTableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho.join(", "), formatarValor(filho.ValorTotalFilho)]);
//         });
//       });

//     // Adiciona o título da tabela Recebimentos
//     const recebimentosTitleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : vendasTitleY + 15; // Ajuste para o caso de não haver tabelas anteriores
//     doc.text("Recebimentos", 10, recebimentosTitleY);

//     doc.autoTable({
//       head: [recebimentosTableData[0]],
//       body: recebimentosTableData.slice(1),
//       startY: recebimentosTitleY + 5, // Início da tabela 5 unidades abaixo do título
//       styles: { fontSize: 10 },
//     });

//     // Bloco Fechamento de Caixa
//     const fechamentoTableData = [['Forma de Pagamento Fechamento Caixa', 'Valor Total']];
//     const fechamentoCaixa = data.reduce((acc, dado) => {
//       if (!dado.DetalhamentoCreditoLoja.length) {
//         acc[dado.FormaPagamentoPai] = (acc[dado.FormaPagamentoPai] || 0) + dado.ValorTotalPai;
//       }
//       dado.DetalhamentoCreditoLoja.forEach((filho) => {
//         acc[filho.FormaPagamentoFilho] = (acc[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
//       });
//       return acc;
//     }, {});

//     Object.entries(fechamentoCaixa).forEach(([formaPagamento, valorTotal]) => {
//       fechamentoTableData.push([formaPagamento, formatarValor(valorTotal)]);
//     });

//     // Adiciona o título da tabela Fechamento de Caixa
//     const fechamentoTitleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : recebimentosTitleY + 15; // Ajuste para o caso de não haver tabelas anteriores
//     doc.text("Fechamento de Caixa", 10, fechamentoTitleY);

//     doc.autoTable({
//       head: [fechamentoTableData[0]],
//       body: fechamentoTableData.slice(1),
//       startY: fechamentoTitleY + 5, // Início da tabela 5 unidades abaixo do título
//       styles: { fontSize: 10 },
//     });

//     // Salva o PDF
//     doc.save("relatorio_caixa_sintetico.pdf");
//   };



//   // Calcular totais
//   const totalVendas = data.reduce((acc, dado) => acc + dado.ValorTotalPai, 0);
//   const totalRecebimentos = data
//     .filter((dado) => dado.DetalhamentoCreditoLoja.length > 0)
//     .reduce((acc, dado) => acc + dado.DetalhamentoCreditoLoja.reduce((sum, filho) => sum + filho.ValorTotalFilho, 0), 0);

//   // Calcular fechamento de caixa, incluindo filhos nas somas das formas de pagamento pai
//   const fechamentoCaixa = data.reduce((acc, dado) => {
//     if (!dado.DetalhamentoCreditoLoja.length) {
//       acc[dado.FormaPagamentoPai] = (acc[dado.FormaPagamentoPai] || 0) + dado.ValorTotalPai;
//     }
//     dado.DetalhamentoCreditoLoja.forEach((filho) => {
//       acc[filho.FormaPagamentoFilho] = (acc[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
//     });
//     return acc;
//   }, {});

//   const totalFechamentoCaixa = Object.values(fechamentoCaixa).reduce((acc, valor) => acc + valor, 0);

//   const agrupamentoRecebimentos = data
//     .filter(dado => dado.DetalhamentoCreditoLoja.length > 0)
//     .map(dado => {
//       const filhosAgrupados = dado.DetalhamentoCreditoLoja.reduce((acc, filho) => {
//         const key = filho.FormaPagamentoFilho.join(", ");
//         if (acc[key]) {
//           acc[key] += filho.ValorTotalFilho;
//         } else {
//           acc[key] = filho.ValorTotalFilho;
//         }
//         return acc;
//       }, {});

//       return {
//         FormaPagamentoPai: dado.FormaPagamentoPai,
//         DetalhamentoCreditoLoja: Object.entries(filhosAgrupados)
//           .filter(([, valorTotal]) => valorTotal > 0) // Exclui os filhos sem valor
//           .map(([formaPagamentoFilho, valorTotalFilho]) => ({
//             FormaPagamentoFilho: formaPagamentoFilho,
//             ValorTotalFilho: valorTotalFilho,
//           })),
//       };
//     });

//   return (
//     <Box>
//       <Text fontSize="lg" fontWeight="bold" mb={2}>Vendas</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Vendas</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {data.map((dado, index) => (
//               <Tr key={index}>
//                 <Td>{dado.FormaPagamentoPai}</Td>
//                 <Td isNumeric>{formatarValor(dado.ValorTotalPai)}</Td>
//               </Tr>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th color={'black'} fontWeight="bold">Total</Th>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalVendas)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Text fontSize="lg" fontWeight="bold" mb={2}>Recebimentos</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Recebimento</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {agrupamentoRecebimentos.map((dado, index) => (
//               <React.Fragment key={index}>
//                 <Tr>
//                   <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
//                   <Td></Td>
//                   <Td></Td>
//                 </Tr>
//                 {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
//                   <Tr key={filhoIndex}>
//                     <Td></Td> {/* Coluna vazia para o filho */}
//                     <Td>{filho.FormaPagamentoFilho}</Td>
//                     <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
//                   </Tr>
//                 ))}
//               </React.Fragment>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th fontWeight="bold" color={'black'}>Total</Th>
//               <Td></Td>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalRecebimentos)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Text fontSize="lg" fontWeight="bold" mb={2}>Fechamento de Caixa</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Fechamento Caixa</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {Object.entries(fechamentoCaixa).map(([formaPagamento, valorTotal], index) => (
//               <Tr key={index}>
//                 <Td>{formaPagamento}</Td>
//                 <Td isNumeric>{formatarValor(valorTotal)}</Td>
//               </Tr>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th fontWeight="bold" color="black">Total</Th>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalFechamentoCaixa)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Button colorScheme="blue" mt={4} onClick={gerarPDF}>Download PDF</Button>
//     </Box>
//   );
// };

// export default TabelaFinanceira;

// -----------

// import React from 'react';
// import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Text } from "@chakra-ui/react";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';



// const TabelaFinanceira = ({ data }) => {

//   const formatarValor = (valor) => {
//     const formatter = new Intl.NumberFormat('pt-BR', {
//       style: 'currency',
//       currency: 'BRL',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//     return formatter.format(valor);
//   };

//   // Agrupamento e soma dos recebimentos para exibição e PDF
//   const agrupamentoRecebimentos = data
//     .filter(dado => dado.DetalhamentoCreditoLoja.length > 0)
//     .map(dado => {
//       const filhosAgrupados = dado.DetalhamentoCreditoLoja.reduce((acc, filho) => {
//         const key = filho.FormaPagamentoFilho.join(", ");
//         if (acc[key]) {
//           acc[key] += filho.ValorTotalFilho;
//         } else {
//           acc[key] = filho.ValorTotalFilho;
//         }
//         return acc;
//       }, {});

//       return {
//         FormaPagamentoPai: dado.FormaPagamentoPai,
//         DetalhamentoCreditoLoja: Object.entries(filhosAgrupados)
//           .filter(([, valorTotal]) => valorTotal > 0) // Exclui filhos sem valor
//           .map(([formaPagamentoFilho, valorTotalFilho]) => ({
//             FormaPagamentoFilho: formaPagamentoFilho,
//             ValorTotalFilho: valorTotalFilho,
//           })),
//       };
//     });

//   // Agrupamento e soma do fechamento de caixa para exibição e PDF
//   // const fechamentoCaixa = data.reduce((acc, dado) => {
//   //   if (dado.ValorTotalPai > 0) {
//   //     acc[dado.FormaPagamentoPai] = (acc[dado.FormaPagamentoPai] || 0) + dado.ValorTotalPai;
//   //   }
//   //   dado.DetalhamentoCreditoLoja.forEach((filho) => {
//   //     if (filho.ValorTotalFilho > 0) {
//   //       acc[filho.FormaPagamentoFilho] = (acc[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
//   //     }
//   //   });
//   //   return acc;
//   // }, {});

//   const fechamentoCaixa = data.reduce((acc, dado) => {
//     if (dado.DetalhamentoCreditoLoja.length) {
//       acc[dado.fechamentoCaixa] = (acc[dado.FormaPagamentoPai] || 0) + dado.ValorTotalPai;
//       console.log(acc[dado.fechamentoCaixa])
//     }
//     dado.DetalhamentoCreditoLoja.forEach((filho) => {
//       if (filho.ValorTotalFilho > 0) {
//         acc[filho.FormaPagamentoFilho] = (acc[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
//       }
//       // acc[filho.FormaPagamentoFilho] = (acc[filho.FormaPagamentoFilho] || 0) + filho.ValorTotalFilho;
//     });
//     return acc;
//   }, {});

//   console.log(fechamentoCaixa, "aqui")


//   const totalVendas = data.reduce((acc, dado) => acc + dado.ValorTotalPai, 0);
//   const totalRecebimentos = data
//     .filter((dado) => dado.DetalhamentoCreditoLoja.length > 0)
//     .reduce((acc, dado) => acc + dado.DetalhamentoCreditoLoja.reduce((sum, filho) => sum + filho.ValorTotalFilho, 0), 0);


//   // const t2 = Object.values(fechamentoCaixa).reduce((acc, valor) => acc + valor, 0);
//   const t1 = Object.values(totalVendas).reduce((acc, valor) => acc + valor, 0);

//   const totalFechamentoCaixa = t1;

//   // Função para gerar o PDF com as tabelas consistentes
//   const gerarPDF = () => {
//     const doc = new jsPDF();
//     const now = new Date();
//     const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
//     doc.text("Fechamento de Caixa - " + formattedDate, 10, 10);

//     // Bloco Vendas
//     const vendasTableData = [['Forma de Pagamento Vendas', 'Valor Total']];
//     data.forEach((dado) => {
//       vendasTableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
//     });

//     const vendasTitleY = 20;
//     doc.text("Vendas", 10, vendasTitleY);
//     doc.autoTable({
//       head: [vendasTableData[0]],
//       body: vendasTableData.slice(1),
//       startY: vendasTitleY + 5,
//       styles: { fontSize: 10 },
//     });

//     // Bloco Recebimentos (agrupado)
//     const recebimentosTableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
//     agrupamentoRecebimentos.forEach((dado) => {
//       dado.DetalhamentoCreditoLoja.forEach((filho) => {
//         recebimentosTableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
//       });
//     });

//     const recebimentosTitleY = doc.lastAutoTable.finalY + 10;
//     doc.text("Recebimentos", 10, recebimentosTitleY);
//     doc.autoTable({
//       head: [recebimentosTableData[0]],
//       body: recebimentosTableData.slice(1),
//       startY: recebimentosTitleY + 5,
//       styles: { fontSize: 10 },
//     });

//     // Bloco Fechamento de Caixa (agrupado e filtrado)
//     const fechamentoTableData = [['Forma de Pagamento Fechamento Caixa', 'Valor Total']];
//     Object.entries(fechamentoCaixa).forEach(([formaPagamento, valorTotal]) => {
//       if (valorTotal > 0) {
//         fechamentoTableData.push([formaPagamento, formatarValor(valorTotal)]);
//       }
//     });

//     const fechamentoTitleY = doc.lastAutoTable.finalY + 10;
//     doc.text("Fechamento de Caixa", 10, fechamentoTitleY);
//     doc.autoTable({
//       head: [fechamentoTableData[0]],
//       body: fechamentoTableData.slice(1),
//       startY: fechamentoTitleY + 5,
//       styles: { fontSize: 10 },
//     });

//     // Salva o PDF
//     doc.save("relatorio_caixa_sintetico.pdf");
//   };

//   return (
//     <Box>
//       <Text fontSize="lg" fontWeight="bold" mb={2}>Vendas</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Vendas</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {data.map((dado, index) => (
//               <Tr key={index}>
//                 <Td>{dado.FormaPagamentoPai}</Td>
//                 <Td isNumeric>{formatarValor(dado.ValorTotalPai)}</Td>
//               </Tr>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th color={'black'} fontWeight="bold">Total</Th>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalVendas)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Text fontSize="lg" fontWeight="bold" mb={2}>Recebimentos</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Recebimento</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {agrupamentoRecebimentos.map((dado, index) => (
//               <React.Fragment key={index}>
//                 <Tr>
//                   <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
//                   <Td></Td>
//                   <Td></Td>
//                 </Tr>
//                 {dado.DetalhamentoCreditoLoja.map((filho, filhoIndex) => (
//                   <Tr key={filhoIndex}>
//                     <Td></Td> {/* Coluna vazia para o filho */}
//                     <Td>{filho.FormaPagamentoFilho}</Td>
//                     <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
//                   </Tr>
//                 ))}
//               </React.Fragment>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th fontWeight="bold" color={'black'}>Total</Th>
//               <Td></Td>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalRecebimentos)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Text fontSize="lg" fontWeight="bold" mb={2}>Fechamento de Caixa</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento Fechamento Caixa</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {Object.entries(fechamentoCaixa).map(([formaPagamento, valorTotal], index) => (
//               <Tr key={index}>
//                 <Td>{formaPagamento}</Td>
//                 <Td isNumeric>{formatarValor(valorTotal)}</Td>
//               </Tr>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th fontWeight="bold" color="black">Total</Th>
//               <Td isNumeric fontWeight="bold">{formatarValor(totalFechamentoCaixa)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>

//       <Button colorScheme="blue" mt={4} onClick={gerarPDF}>Download PDF</Button>
//     </Box>
//   );
// };

// export default TabelaFinanceira;

// import React from 'react';
// import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Text } from "@chakra-ui/react";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const TabelaFinanceira = ({ data }) => {
//   const formatarValor = (valor) => {
//     return new Intl.NumberFormat('pt-BR', {
//       style: 'currency',
//       currency: 'BRL',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     }).format(valor);
//   };

//   const agruparDados = (dados) => {
//     return dados
//       .filter(dado => dado.Filhos.length > 0)
//       .map(dado => {
//         const filhosAgrupados = dado.Filhos.reduce((acc, filho) => {
//           const formaPagamentoFilho = filho.FormaPagamento || 'Sem Pagamento';
//           acc[formaPagamentoFilho] = (acc[formaPagamentoFilho] || 0) + filho.ValorPago;
//           return acc;
//         }, {});
//         return {
//           FormaPagamentoPai: dado.FormaPagamentoPai || 'Sem Pagamento',
//           Filhos: Object.entries(filhosAgrupados).map(([FormaPagamentoFilho, ValorPago]) => ({
//             FormaPagamentoFilho: FormaPagamentoFilho,
//             ValorTotalFilho: ValorPago,
//           })),
//         };
//       });
//   };

//   const totalizarDados = (dados) => {
//     return dados.reduce((acc, dado) => acc + dado.ValorTotalPai, 0);
//   };

//   const totalAgrupado = (agrupamento) => {
//     return agrupamento.reduce((acc, dado) => {
//       return acc + dado.Filhos.reduce((sum, filho) => sum + filho.ValorTotalFilho, 0);
//     }, 0);
//   };

//   const dadosEmitidos = data.emitidos || [];
//   const dadosPagos = data.pagos || [];

//   const agrupamentoEmitidos = agruparDados(dadosEmitidos);
//   const agrupamentoPagos = agruparDados(dadosPagos);
//   const totalVendasEmitidos = totalizarDados(dadosEmitidos);
//   const totalVendasPagos = totalizarDados(dadosPagos);
//   const totalRecebimentosEmitidos = totalAgrupado(agrupamentoEmitidos);
//   const totalRecebimentosPagos = totalAgrupado(agrupamentoPagos);

//   const gerarPDF = () => {
//     const doc = new jsPDF();
//     const now = new Date();
//     const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
//     doc.text("Relatório Financeiro - " + formattedDate, 10, 10);

//     const gerarTabelaPDF = (titulo, agrupamento) => {
//       const tableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
//       agrupamento.forEach(dado => {
//         dado.Filhos.forEach(filho => {
//           tableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
//         });
//       });
//       const titleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20;
//       doc.text(titulo, 10, titleY);
//       doc.autoTable({
//         head: [tableData[0]],
//         body: tableData.slice(1),
//         startY: titleY + 5,
//         styles: { fontSize: 10 },
//       });
//     };

//     gerarTabelaPDF("Emitidos - Vendas", agrupamentoEmitidos);
//     gerarTabelaPDF("Pagos - Vendas", agrupamentoPagos);
//     gerarTabelaPDF("Emitidos - Recebimentos", agrupamentoEmitidos);
//     gerarTabelaPDF("Pagos - Recebimentos", agrupamentoPagos);

//     doc.save("relatorio_financeiro.pdf");
//   };

//   const TabelaAgrupada = ({ titulo, dados, agrupamento, total }) => (
//     <>
//       <Text fontSize="lg" fontWeight="bold" mb={2}>{titulo}</Text>
//       <TableContainer mb={8}>
//         <Table variant="striped" size="sm">
//           <Thead>
//             <Tr>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Forma de Pagamento</Th>
//               <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Forma de Recebimento</Th>
//               <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Valor Total</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {agrupamento.map((dado, index) => (
//               <React.Fragment key={index}>
//                 <Tr>
//                   <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
//                   <Td></Td>
//                   <Td></Td>
//                 </Tr>
//                 {dado.Filhos.map((filho, filhoIndex) => (
//                   <Tr key={filhoIndex}>
//                     <Td></Td>
//                     <Td>{filho.FormaPagamentoFilho}</Td>
//                     <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
//                   </Tr>
//                 ))}
//               </React.Fragment>
//             ))}
//           </Tbody>
//           <tfoot>
//             <Tr>
//               <Th fontWeight="bold" color="black">Total</Th>
//               <Td></Td>
//               <Td isNumeric fontWeight="bold">{formatarValor(total)}</Td>
//             </Tr>
//           </tfoot>
//         </Table>
//       </TableContainer>
//     </>
//   );

//   return (
//     <Box>
//       <TabelaAgrupada titulo="Emitidos - Vendas" dados={dadosEmitidos} agrupamento={agrupamentoEmitidos} total={totalVendasEmitidos} />
//       <TabelaAgrupada titulo="Pagos - Vendas" dados={dadosPagos} agrupamento={agrupamentoPagos} total={totalVendasPagos} />
//       <TabelaAgrupada titulo="Emitidos - Recebimentos" dados={dadosEmitidos} agrupamento={agrupamentoEmitidos} total={totalRecebimentosEmitidos} />
//       <TabelaAgrupada titulo="Pagos - Recebimentos" dados={dadosPagos} agrupamento={agrupamentoPagos} total={totalRecebimentosPagos} />
//       <Button colorScheme="blue" mt={4} onClick={gerarPDF}>Download PDF</Button>
//     </Box>
//   );
// };

// export default TabelaFinanceira;



import React from 'react';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Text } from "@chakra-ui/react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TabelaFinanceira = ({ data }) => {
  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);
  };

  const agruparDados = (dados) => {
    return dados
      .filter(dado => dado.Filhos.length > 0)
      .map(dado => {
        // Usar o valor original do pai
        const valorTotalPai = dado.ValorTotalPai || 0; // Assumindo que o valor original dos pais está na propriedade ValorTotalPai
  
        const filhosAgrupados = dado.Filhos.reduce((acc, filho) => {
          const formaPagamentoFilho = filho.FormaPagamento || 'Sem Pagamento';
          acc[formaPagamentoFilho] = (acc[formaPagamentoFilho] || 0) + filho.ValorPago;
          return acc;
        }, {});
  
        return {
          FormaPagamentoPai: dado.FormaPagamentoPai || 'Sem Pagamento',
          Filhos: Object.entries(filhosAgrupados).map(([FormaPagamentoFilho, ValorPago]) => ({
            FormaPagamentoFilho: FormaPagamentoFilho,
            ValorTotalFilho: ValorPago,
          })),
          ValorTotalPai: valorTotalPai // Agora estamos usando o valor original do pai
        };
      });
  };
  

  const dadosEmitidos = data.emitidos || [];
  const dadosPagos = data.pagos || [];

  console.log(dadosEmitidos, dadosPagos)

  const agrupamentoEmitidos = agruparDados(dadosEmitidos);
  const agrupamentoPagos = agruparDados(dadosPagos);

  const gerarPDF = () => {
    const doc = new jsPDF();
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
    doc.text("Relatório Financeiro - " + formattedDate, 10, 10);

    const gerarTabelaPDF = (titulo, agrupamento) => {
      const tableData = [['Forma de Pagamento', 'Valor Total']];
      agrupamento.forEach(dado => {
        tableData.push([dado.FormaPagamentoPai, formatarValor(dado.ValorTotalPai)]);
      });
      const titleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20;
      doc.text(titulo, 10, titleY);
      doc.autoTable({
        head: [tableData[0]],
        body: tableData.slice(1),
        startY: titleY + 5,
        styles: { fontSize: 10 },
      });
    };

    gerarTabelaPDF("Emitidos - Vendas", agrupamentoEmitidos);
    gerarTabelaPDF("Pagos - Vendas", agrupamentoPagos);

    // Para Recebimentos
    const gerarTabelaPDFRecebimentos = (titulo, agrupamento) => {
      const tableData = [['Forma de Pagamento', 'Forma de Recebimento', 'Valor Total']];
      agrupamento.forEach(dado => {
        dado.Filhos.forEach(filho => {
          tableData.push([dado.FormaPagamentoPai, filho.FormaPagamentoFilho, formatarValor(filho.ValorTotalFilho)]);
        });
      });
      const titleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20;
      doc.text(titulo, 10, titleY);
      doc.autoTable({
        head: [tableData[0]],
        body: tableData.slice(1),
        startY: titleY + 5,
        styles: { fontSize: 10 },
      });
    };

    gerarTabelaPDFRecebimentos("Emitidos - Recebimentos", agrupamentoEmitidos);
    gerarTabelaPDFRecebimentos("Pagos - Recebimentos", agrupamentoPagos);

    doc.save("relatorio_financeiro.pdf");
  };

  const TabelaAgrupada = ({ titulo, agrupamento }) => (
    <>
      <Text fontSize="lg" fontWeight="bold" mb={2}>{titulo}</Text>
      <TableContainer mb={8}>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="center" width="50%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Forma de Pagamento</Th>
              <Th isNumeric width="50%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Valor Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {agrupamento.map((dado, index) => (
              <Tr key={index}>
                <Td fontWeight="bold">{dado.FormaPagamentoPai}</Td>
                <Td isNumeric>{formatarValor(dado.ValorTotalPai)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );

  const TabelaRecebimentos = ({ titulo, agrupamento }) => (
    <>
      <Text fontSize="lg" fontWeight="bold" mb={2}>{titulo}</Text>
      <TableContainer mb={8}>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Forma de Pagamento</Th>
              <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Forma de Recebimento</Th>
              <Th isNumeric width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'}>Valor Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {agrupamento.map((dado, index) => (
              <React.Fragment key={index}>
                {dado.Filhos.map((filho, filhoIndex) => (
                  <Tr key={filhoIndex}>
                    <Td>{dado.FormaPagamentoPai}</Td>
                    <Td>{filho.FormaPagamentoFilho}</Td>
                    <Td isNumeric>{formatarValor(filho.ValorTotalFilho)}</Td>
                  </Tr>
                ))}
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <Box>
      <TabelaAgrupada titulo="Vendas" agrupamento={agrupamentoEmitidos} />
      <TabelaAgrupada titulo="Pagos - Vendas" agrupamento={agrupamentoPagos} />
      <TabelaRecebimentos titulo="Recebimentos" agrupamento={agrupamentoEmitidos} />
      <TabelaRecebimentos titulo="Pagos - Recebimentos" agrupamento={agrupamentoPagos} />
      <Button colorScheme="blue" mt={4} onClick={gerarPDF}>Download PDF</Button>
    </Box>
  );
};

export default TabelaFinanceira;
